<template>
    <section class="info-chat-grupo">
        <div class="row mx-0 border-bottom" style="min-height: 57px">
            <div class="col-auto my-auto">
                <p class="f-17 f-500"> Información del grupo</p>
            </div>
            <div class="col-auto ml-auto my-auto">
                <div class="d-middle">
                    <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="rounded-pill py-2 border-0 shadow-sm">
                        <div slot="content" class="text-86">
                            Eliminar Grupo
                        </div>
                        <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer" @click="$refs.modalEliminarGrupo.toggle()">
                            <i class="icon-delete-outline" />
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="rounded-pill py-2 border-0 shadow-sm">
                        <div slot="content" class="text-86">
                            Editar Grupo
                        </div>
                        <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-2" @click="openEditarGrupo = true">
                            <i class="icon-pencil-outline" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="contenedor-info scroll-none overflow-auto">
            <div class="row mx-0 mt-3">
                <div class="col-12 text-center">
                    <img :src="grupo.imagen" alt="" class="img-grupo" />
                </div>
            </div>
            <div class="div-divisor my-4" />
            <div class="row mx-0 mb-3">
                <div class="col-10">
                    <p class="f-15 f-500">Añadir más residentes</p>
                    <p class="f-13 text-86">Nuevos participantes</p>
                </div>
                <div class="col-2 my-auto text-right"> 
                    <i class="icon-account-plus-outline f-25 cr-pointer" @click="openAddUsers = true" />
                </div>
            </div>
            <div class="row mx-0 mb-3">
                <div class="col-10">
                    <p class="f-15 f-500">Designar Administradores</p>
                    <p class="f-13 text-86">Siempre podrán enviar mensajes</p>
                </div>
                <div class="col-2 my-auto text-right">
                    <i class="icon-account-checked-outline f-18 cr-pointer" @click="openAdmins = true" />
                </div>
            </div>
            <div class="row mx-0 mb-3">
                <div class="col-10">
                    <p class="f-15 f-500">Envio de mensajes</p>
                    <p class="f-13 text-86">{{ grupo.escritura == 1 ? 'Solo los admins' : 'Todos los participantes'}}</p>
                </div>
                <div class="col-2 my-auto text-right">
                    <i class="icon-right-open f-20 cr-pointer" @click="envioMensajes = true" />
                </div>
            </div>
            <div class="row mx-0 mb-3">
                <div class="col-10">
                    <p class="f-15 f-500">Silenciar chat</p>
                    <p class="f-13 text-86">Se ocultará el chat para los participantes</p>
                </div>
                <div class="col-2 my-auto text-right">
                    <el-switch v-model="grupo.estado" :active-value="1" :inactive-value="0" active-color="#00D9B5" inactive-color="#E6E6E6" @change="setEstadoGrupo" />
                </div>
            </div>
            <div class="row mx-0 mb-3">
                <div class="col-10">
                    <p class="f-15 f-500">Eliminar residentes</p>
                    <p class="f-13 text-86">Retirar participantes del chat</p>
                </div>
                <div class="col-2 my-auto text-right">
                    <i class="icon-delete-outline f-17 cr-pointer" @click="openEliminar = true" />
                </div>
            </div>
            <div class="div-divisor my-4" />
            <div class="row mx-0">
                <p class="col-12 f-15 f-500 mb-3">Residentes del grupo</p>
            </div>
            
            <usuarios-grupo />
        </div>
        
        <!-- partials -->
        <modal-eliminar ref="modalEliminarGrupo" titulo="Eliminar grupo" mensaje="¿Desea eliminar este grupo?" tamano="modal-md" class="f-15 text-center" @eliminar="eliminarGrupo" />
        <!-- "modal" editar grupo-->
        <modalEditarGrupo :show="openEditarGrupo" @close="openEditarGrupo = false"/>
        <!-- "modal" agregar residentes al grupo -->
        <modal-agregar-usuarios :show="openAddUsers" @close="openAddUsers = false" />
        <!-- "modal" Designar Administradores -->
        <modal-asignar-admins :show="openAdmins" @close="openAdmins = false" />
        <!-- "modal" Envio de mensajes -->
        <div class="envio-mensajes" :class="envioMensajes ? 'activo':'inactivo'">
            <div class="row header mx-0 py-2">
                <div class="col-12 d-flex ">
                    <i class="icon-arrow-left-circle f-25 mr-4 my-auto cr-pointer" @click="envioMensajes = false" />
                    <p class="f-17 f-500 my-auto">Envio de mensajes</p>
                </div>
            </div>
            <div class="row mx-0 justify-content-center mt-5">
                <div class="col-10 text-left">
                    <p class="f-15">Selecciona quienes pueden enviar mensajes en este grupo</p>
                </div>
            </div>
            <div class="row mx-0 mt-4 justify-content-center">
                <div class="col-10">
                    <div class="f-14 mb-3">
                        <label class="d-middle" for="">
                            <input v-model="grupo.escritura" :value="2" type="radio" class="option-input opt-gris radio mr-2" /> 
                            Todos los residentes del grupo
                        </label>
                    </div>
                    <div class="f-14 mb-3">
                        <label class="d-middle" for="">
                            <input v-model="grupo.escritura" :value="1" type="radio" class="option-input opt-gris radio mr-2" /> 
                            Solo los administradores
                        </label>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-4 justify-content-center">
                <div class="col-10">
                    <button class="btn btn-general f-14 f-300 w-100" @click="setEscrituraGrupo(grupo.escritura)">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
        <!-- "modal" eliminar residentes -->
        <modal-eliminar-residentes :show="openEliminar" @close="openEliminar = false" />
    </section>
</template>
<script>
import Chats from '~/services/chats/chat-admin'
import {mapGetters, mapMutations} from 'vuex'
export default {
    components:{
        usuariosGrupo: () => import('../components/usersGrupo'),
        modalEditarGrupo: () => import('../components/modalEditarGrupo'),
        modalAgregarUsuarios: () => import('../components/modalAgregarUsuarios'),
        modalAsignarAdmins: () => import('../components/modalAsignarAdmins'),
        modalEliminarResidentes: () => import('../components/modalEliminarResidentesGrupo.vue'),
    },
    data(){
        return{
            editarGrupoModal: false,
            agregarResidentes: false,
            verResidentesSeleccionado: false,
            seleccionarAdministradores: false,
            eliminarResidentes: false,
            envioMensajesConfig: 1,

            openEditarGrupo:false,
            openAddUsers:false,
            openAdmins:false,
            openEliminar:false,
            envioMensajes: false,

        }
    },
    computed:{
        ...mapGetters({
            grupo:'chatsGrupos/grupoActivo'
        })
    },
    methods:{
        ...mapMutations({
            initTabGrupos: 'chatsGrupos/initTabGrupos',
            deleteGrupoLista: 'chatsGrupos/deleteGrupoLista',
        }),
        async setEscrituraGrupo(value){
            try {
                
                const form = {
                    escritura:value
                }
              const {data} = await Chats.setEscrituraGrupo(this.grupo.id_grupo, form)
              this.notificacion('','Escritura del grupo actualizada','success')
              this.envioMensajes = false
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async setEstadoGrupo(value){
            try {
                
                const form = {
                    estado:value
                }
              const {data} = await Chats.setEstadoGrupo(this.grupo.id_grupo, form)
              this.notificacion('','Estado del grupo actualizado','success')
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async eliminarGrupo(){
            try {
               
              const {data} = await Chats.deleteGrupo(this.grupo.id_grupo)
              this.$refs.modalEliminarGrupo.toggle()
              this.notificacion('Éxito','Grupo eliminado','success')
              this.deleteGrupoLista(this.grupo.id_grupo)
              this.initTabGrupos()
            } catch (error) {
                this.errorCatch(error)
            }
        },
        
        editarGrupo(){
            this.$refs.modalEditarGrupo.toggle()
        },
        editarGrupo2(){
            this.editarGrupoModal = !this.editarGrupoModal
        },
        guardarEdicionGrupo(){
            this.editarGrupoModal = !this.editarGrupoModal
        },
        guardarGrupo(){
            this.$refs.modalEditarGrupo.toggle()
        },
        abrirAgregarResidentes(){
            this.agregarResidentes = !this.agregarResidentes
        },
        guardarAgregarResidentes(){
            this.agregarResidentes = !this.agregarResidentes
        },
        abrirEnvioMensajes(){
            this.envioMensajes = !this.envioMensajes
        },
        guardarEnvioMensajes(){
            this.envioMensajes = !this.envioMensajes
        },
    }
    
}
</script>
<style lang="scss" scoped>
.info-chat-grupo{
    position: relative;
    .wh-164px{
        max-width: 164px;
        min-width: 164px;
        max-height: 164px;
        min-height: 164px;
    }
    .img-grupo{
        @extend .wh-164px;
        border-radius: 50%;
        border: 2px solid #F5F5F5;
    }
    .contenedor-info{
        height: calc(100vh - 115px);
    }
    .div-divisor{
        height: 5px;
        background: #F5F5F5;
    }
    .icon-navigation{
        transform: rotate( 90deg );
    }
    .tipo-user{
        i.icon-buildings{
            &:before{
                margin-left: 0px;
            }
        }
        i.icon-account-outline{
            font-size: 25px;
        }
    }
    @keyframes slidein {
        from {
            width: 0%;
        }

        to {
            width: 100%;
        }
    }

    .editar-grupo, .agregar-residentes, .seleccionar-admins, .envio-mensajes{
        background: #fff;
        height: calc(100vh - 56px);
        position: absolute;
        top: 0;
        width: 103.9%;
        right: -393.77px;
        display: none;
        &.activo{
            transform: translateX(-379px);
            display: block !important;
            animation-duration: 0.35s;
            animation-name: slidein;
        }
        .header{
            background: #f1f1f1;
        }
        .contenedor-img-grupo{
            @extend .wh-164px;
            border-radius: 50%;
            border: 2px solid #F5F5F5;
            position:relative;
            .bg-hover{
                @extend .wh-164px;
                border-radius: 50%;
                background: #000;
                opacity: 0;
                position: absolute;
                transition: .35s;
            }
            .img-grupo{
                border: none !important;
            }
            &:hover{
                .bg-hover{
                    opacity: 0.7;
                    transition: .45s;

                }
            }
        }
        .contenedor-residentes{
            height: calc(100vh - 206px);
        }
        .contenedor-residentes-admins{
            height: calc(100vh - 135px);
        }
        .mas-opciones.opt{
            position: relative;
            .cantidad{
                position: absolute;
                color: white;
                background: #FF4D59;
                border-radius: 7px;
                font-size: 8px;
                top: -6px;
                right: -10px;
                font-weight: 500;
            }
        }
    }
}
</style>